import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Img from "gatsby-image/withIEPolyfill"
import ArticleItem from "../../components/articleItem"
import SEO from "../../components/seo"
import "../../styles/blog.scss"

function ArticleTemplate(props) {
  const filters = {
    latest: props.data.allContentfulBlogs.edges,
    marketing: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("marketing")
    ),
    commerce: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("adobe-commerce")
    ),
    headless: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("headless-commerce")
    ),
    culture: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("culture")
    ),
    ourPicks: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("ourPicks")
    ),
    ecommerce: props.data.allContentfulBlogs.edges.filter(blog =>
      blog?.node?.tags?.includes("ecommerce-reviews")
    ),
  }

  const labels = {
    latest: "Latest",
    marketing: "Marketing",
    commerce: "Adobe-commerce",
    culture: "Culture",
    ourPicks: "Our Picks",
    headless: "Headless-commerce",
    ecommerce: "Ecommerce-reviews",
  }

  const description = {
    latest:
      "Real-time and topical posts on all things commerce, as and when we publish them.",
    marketing:
      "Improve your marketing strategy, get insights on how to drive growth and loyalty, and build high performance stores that convert.",
    commerce:
      "Gain deep insights on the latest e-commerce trends and technologies, and leverage intelligent features to build differentiation.",
    culture:
      "Take a walk with us and meet the faces behind Coderapper. We share our thoughts on our culture, progress, and processes.",
    ourPicks:
      "Okay we get it, you want the good stuff. Here are some handpicked posts from our blog that our readers love!",
    ecommerce:
      " Honest, in-depth reviews of e-commerce platforms, tools, and technologies. Make informed decisions with our expert evaluations and insights.",
    headless:
      " Dive into the world of decoupled e-commerce. Discover the benefits, best practices, and innovations driving headless commerce forward.",
  }

  const [activeTab, setActiveTab] = useState("latest")
  const changeTab = (e, active) => {
    e.preventDefault()
    setActiveTab(active)
  }
  return (
    <Layout data={{ headerType: "light" }}>
      <SEO
        title="Article"
        description=""
        image={`https:${filters.latest[0].node.heroImage.fluid.src}`}
      />
      <div className="container test">
        <section className="blog-header ">
          <Link to={`/article`}>
            <p className="title-tag">/ Article</p>
          </Link>
          <h1 className="page-title">
            All things e-commerce, for inspired entrepreneurs.
          </h1>
        </section>

        <section className="feature-banner">
          <div className="feature-banner__content">
            <h4 className="title-tag">/ Featured</h4>
            <Link
              to={`/article/${filters.latest[0].node.tags}/${filters.latest[0].node.slug}`}
            >
              <h2 className="feature-banner__title">
                {filters.latest[0].node.title}
              </h2>
            </Link>
            <p className="feature-banner__description">
              {filters.latest[0].node.seoMetaDescription.seoMetaDescription}
            </p>
            <figure className="article-author">
              {filters.latest[0].node?.author?.image &&
                filters.latest[0].node.author.image.fluid && (
                  <Img
                    className="article-author__image"
                    fluid={filters.latest[0]?.node.author.image.fluid}
                  />
                )}
              <figcaption className="article-author__details">
                <h5 className="article-author__name">
                  {filters.latest[0]?.node?.author?.name}
                </h5>
                <h6 className="article-author__role">
                  {filters.latest[0]?.node?.author?.title}
                </h6>
              </figcaption>
            </figure>
          </div>
          <div className="feature-banner__image">
            {filters.latest[0].node.heroImage &&
              filters.latest[0].node.heroImage.fluid && (
                <Img
                  className="feature-banner__image_hero"
                  fluid={filters.latest[0]?.node?.heroImage?.fluid}
                />
              )}
          </div>
        </section>

        <section>
          <div className="cdr-tab">
            <nav className="cdr-tab__nav">
              <ul className="cdr-tab__list">
                <li>
                  <a
                    className={`cdr-tab__item ${
                      activeTab === "latest" ? "cdr-tab__item--active" : ""
                    }`}
                    href=""
                    onClick={e => {
                      changeTab(e, "latest")
                    }}
                  >
                    Latest
                  </a>
                </li>
                {filters.marketing.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "marketing" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "marketing")
                      }}
                    >
                      Marketing
                    </a>
                  </li>
                )}
                {filters.commerce.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "commerce" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "commerce")
                      }}
                    >
                      Adobe-commerce
                    </a>
                  </li>
                )}
                {filters.commerce.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "headless" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "headless")
                      }}
                    >
                      Headless-commerce
                    </a>
                  </li>
                )}
                {filters.culture.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "culture" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "culture")
                      }}
                    >
                      Culture
                    </a>
                  </li>
                )}
                {filters.ourPicks.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "ourPicks" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "ourPicks")
                      }}
                    >
                      Our Picks
                    </a>
                  </li>
                )}
                {filters.ecommerce.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "ecommerce" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "ecommerce")
                      }}
                    >
                      Ecommerce-Reviews
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="cdr-tab__content">
            <div className="cdr-tab__banner">
              <h2 className="cdr-tab__banner-title">{labels[activeTab]}</h2>
              <p className="cdr-tab__banner-description">
                {description[activeTab]}
              </p>
            </div>

            <div className="cdr-tab__content-item">
              {filters[activeTab].map((post, key) => {
                return (
                  <ArticleItem
                    key={post.node.id}
                    artitleId={post.node.id}
                    artitleSlug={post.node.slug}
                    artitleTitle={post.node.title}
                    artitleTag={post.node.tags}
                    articleImage={post.node?.heroImage}
                    author={post.node.author?.name}
                    authorTitle={post.node.author?.title}
                    authorImage={post.node.author?.image}
                    otherProps={{ ...post.node }}
                  />
                )
              })}
            </div>
          </div>
        </section>

        {/* <div className="btn-more"><a href="#">Load more</a></div> */}
      </div>
    </Layout>
  )
}

export default ArticleTemplate
export const articlePageQuery = graphql`
  query ArticleTemplateQuery {
    allContentfulBlogs(
      sort: { fields: publishDate, order: DESC }
      filter: { node_locale: { eq: "en-US" }, blogType: { eq: false } }
    ) {
      edges {
        node {
          id
          node_locale
          tags
          title
          slug
          metaDescription
          seoMetaDescription {
            seoMetaDescription
          }
          updatedAt
          createdAt
          heroImage {
            fluid {
              srcSet
              src
            }
          }
          author {
            company
            name
            title
            image {
              fluid {
                srcSet
                src
              }
            }
          }
          description {
            raw
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        itemCount
        perPage
        totalCount
      }
    }
  }
`
