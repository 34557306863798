import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const Bold = ({ children }) => <span>{children}</span>
const Text = ({ children }) => <p>{children}</p>

export const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <Img
            className="blog-body__image"
            key={node.data.contentful_id}
            fluid={node.data.target.fluid}
          />
        </>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // if (children[0].type === "code") {
      //   const isAudio = children[0].props.children.includes(
      //     "speechkit-io-iframe"
      //   )
      //   const className = isAudio
      //     ? `embed-wrapper audio-embed`
      //     : "embed-wrapper video-embed"
      //   function createMarkup() {
      //     return { __html: children[0].props.children }
      //   }
      //   return (
      //     <div className={className} dangerouslySetInnerHTML={createMarkup()} />
      //   )
      // } else {
      //   return <Text>{children}</Text>
      // }
      return <Text>{children}</Text>
    },
  },
}

const ArticleItem = props => {
  return (
    <article className="article">
      <div className="article__image">
        {props.articleImage && props.articleImage.fluid && (
          <Img
            className="article__image_hero"
            fluid={props.articleImage.fluid}
          />
        )}
      </div>
      <div className="article__content">
        <Link to={`/article/${props.artitleTag}/${props.artitleSlug}`}>
          <h2 className="article__title">{props.artitleTitle}</h2>
        </Link>
        <p className="feature-banner__description">
          {props.otherProps.seoMetaDescription?.seoMetaDescription}
        </p>
        <figure className="article-author">
          {props.authorImage && props.authorImage.fluid && (
            <Img
              className="article-author__image"
              fluid={props.authorImage.fluid}
            />
          )}
          <figcaption className="article-author__details">
            <h5 className="article-author__name">{props.author}</h5>
            <h6 className="article-author__role">{props.authorTitle}</h6>
          </figcaption>
        </figure>
      </div>
      <div className="article__link">
        {/* <a href="#"><img className="article__icon" src={Plus} /></a> */}
        <Link to={`/${props.artitleSlug}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="72"
            viewBox="0 0 31 72"
            className="article__icon"
          >
            <g transform="translate(-489 13)">
              <text className="plus" transform="translate(489 -13)">
                <tspan x="0" y="46">
                  +
                </tspan>
              </text>
            </g>
          </svg>
        </Link>
      </div>
    </article>
  )
}
export default ArticleItem
